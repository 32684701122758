<template>
  <div>
    <div class="title">
      <van-icon name="arrow-left" class="lefticon" @click="goBack()" />
      <p>检测版本</p>
    </div>
    <section class="content">
      <p class="content-text">请选择查重版本</p>
      <ul class="top-select">
        <li v-for="list in navList" :class="list.id == isSelect ? 'lis ov' : 'lis ot'" :key="list.id"
          @click="toChooese(list)">
          <div class="libtm">
            <p class="top-icons">
              <img
                :src="list.id == isSelect ? `https://www.pepertool.cn/asset/images/icon/${list.id}b.png` : `https://www.pepertool.cn/asset/images/icon/${list.id}a.png`"
                class="isimgs" />
            </p>
            <p :class="list.id == isSelect ? 'top-text' : ''">
              <span style="font-size:0.37rem">{{ list.sname }}</span>
            </p>
          </div>
          <div :class="list.id == isSelect ? 'des' : 'desc'">
            <p style="height:100%;width:100%">
              <span style="font-size: 0.25rem;">{{ list.sintro }}</span>
            </p>
          </div>
          <div class="icon-yes" v-if="list.id == isSelect">
            <img src="../assets/img/index_icon_yes.png" class="show" />
          </div>
        </li>
      </ul>
    </section>
    <div class="bbcontent">
      <div class="bbtext">
        {{ banben }}(
        <span style="color: #f5602a;font-size:0.3rem">{{ price }}元{{ pricetype | filterPrice }}</span>)
      </div>
    </div>
    <div class="shuoming">
      <p v-html="desc" style="text-indent:2em"></p>
    </div>
    <van-button color="#228ef7" block class="btn" @click="toCheck(isSelect, banben, price, pricetype, desc, cid)">
      <span style="font-size: 0.37rem;">提交查重</span>
    </van-button>
    <p class="petty">如需帮助请联系客服微信cx5078</p>
  </div>
</template>

<script>
import { Dialog } from "vant";
export default {
  data() {
    return {
      edtion: {
        isyes: require("../assets/img/index_icon_yes.png"),
        chooseOt: require("../assets/img/index_icon1_ot.png"),
        chooseOv: require("../assets/img/index_icon1_ov.png")
      },
      navList: [],
      isSelect: "",
      desc: "",
      pricetype: "",
      price: "",
      banben: "",
      cid: "",
      ids: ""
    };
  },
  created() {
    this.ids = this.$route.query.id;
    this.getData();
  },
  filters: {
    filterPrice: function (value) {
      if (value == 1) {
        value = "/万字";
        return value;
      } else if (value == 2) {
        value = "/篇";
        return value;
      } else if (value == 0) {
        value = "/千字";
        return value;
      }
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    toCheck(id, banben, price, pricetype, desc, cid) {
      console.log(id, banben, price, pricetype, desc, cid, 123);
      if (window.sessionStorage.getItem("Token")) {
        this.$router.push({
          path: "/ccpage",
          query: {
            id: id,
            banben: banben,
            price: price,
            pricetype: pricetype,
            desc: desc,
            cid: cid
          }
        });
      } else {
        Dialog.confirm({
          title: "提示",
          message: "你还没有登录，即将跳转登录页",
          width: "80%"
        }).then(() => {
          this.$router.push({
            path: "/ccpage",
            query: {
              id: id,
              banben: banben,
              price: price,
              pricetype: pricetype,
              desc: desc,
              cid: cid
            }
          });
        });
      }
    },
    toChooese(item) {
      console.log(item, 231);
      this.isSelect = item.id;
      this.desc = item.intro;
      this.pricetype = item.price_type;
      this.price = item.price;
      this.banben = item.name;
      this.cid = item.cid;
    },
    getData() {
      this.$http.getjcList().then(res => {
        this.navList = res[this.ids];
        this.isSelect = res[this.ids][0].id;
        this.desc = res[this.ids][0].intro;
        this.pricetype = res[this.ids][0].price_type;
        this.price = res[this.ids][0].price;
        this.banben = res[this.ids][0].name;
        this.cid = res[this.ids][0].cid;
      });
    }
  }
};
</script>

<style  scoped>
.title {
  display: flex;
  background: #228ef7;
}

.lefticon {
  line-height: 0.8rem;
  padding: 0 0.3rem;
  color: #fff;
  font-size: 0.4rem;
}

.title p {
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  color: #fff;
  flex: 0.85;
}

.icon {
  line-height: 0.8rem;
  font-size: 0.48rem;
  width: 11%;
}

.title span {
  line-height: 0.8rem;
  width: 85%;
  font-size: 0.3rem;
  text-align: center;
}

.top-select {
  padding: 0 0.3rem 0;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ot {
  border: 1px solid #c7c7c7;
}

.lis {
  width: 3.3rem;
  height: 1.4rem;
  border-radius: 0.1rem;
  position: relative;
  margin-bottom: 0.2rem;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.ov {
  border: 1px solid #f5602a;
}

.isimgs {
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.libtm {
  height: 0.85rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
  font-size: 0.37rem;
}

.top-icons {
  width: 0.8rem;
  height: 0.8rem;
  display: inline-block;
  overflow: hidden;
  position: relative;
  padding-right: 0.07rem;
}

.top-text {
  color: #f5602a;
}

.des {
  color: #e37a55;
  background: #fff5f2;
  width: 100%;
  padding: 0 6px;
  font-size: 15px;
  height: 0.49rem;
  line-height: 0.49ren;
  line-height: 0.49rem;
}

.desc {
  color: #999;
  background: #f7f8f9;
  width: 100%;
  padding: 0 6px;
  font-size: 15px;
  height: 0.49rem;
  line-height: 0.49rem;
}

.content {
  background: #fff;
  padding-bottom: 0.3rem;
  border-bottom: 0.5px #d9d9d9 solid;
}

.content-text {
  padding: 0 0.3rem;
  font-size: 0.3rem;
  color: #999;
  height: 0.9rem;
  line-height: 0.9rem;
}

.icon-yes {
  position: absolute;
  right: 0rem;
  top: 0rem;
  width: 0.5rem;
  height: 0.7rem;
}

.show {
  width: 100%;
  height: 100%;
}

.bbtext {
  padding: 0.3rem 0.3rem 0;
  font-size: 0.3rem;
  color: #666;
}

.bbcontent {
  margin-top: 0.4rem;
}

.shuoming {
  padding: 0.3rem 0.3rem 0;
  font-size: 0.3rem;
  line-height: 0.5rem;
  color: #999;
}

.btn {
  width: 90%;
  text-align: center;
  margin: 1.2rem auto;
  font-size: 0.37rem;
  border-radius: 0.1rem;
}

.petty {
  font-size: 0.28rem;
  color: #999;
  text-align: center;
  padding: 25px 0;
}</style>